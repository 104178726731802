@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  /* border: 1px solid red; */
}

@font-face {
  font-family: "OutFit Thin";
  src: url("./assets/font/Outfit-Thin.ttf") format("truetype");
}
@font-face {
  font-family: "OutFit Light";
  src: url("./assets/font/Outfit-Light.ttf") format("truetype");
}
@font-face {
  font-family: "OutFit ExtraLight";
  src: url("./assets/font/RedHatDisplay-Light.ttf") format("truetype");
}

@font-face {
  font-family: "OutFit Regular";
  src: url("./assets/font/RedHatDisplay-Regular.ttf") format("truetype");
}
@font-face {
  font-family: "OutFit Medium";
  src: url("./assets/font/RedHatDisplay-Medium.ttf") format("truetype");
}

@font-face {
  font-family: "OutFit SemiBold";
  src: url("./assets/font/RedHatDisplay-SemiBold.ttf") format("truetype");
}
@font-face {
  font-family: "OutFit Bold";
  src: url("./assets/font/RedHatDisplay-Bold.ttf") format("truetype");
}
@font-face {
  font-family: "OutFit ExtraBold";
  src: url("./assets/font/RedHatDisplay-ExtraBold.ttf") format("truetype");
}

@font-face {
  font-family: "Erode Bold";
  src: url("/public/font/Erode-Bold.ttf") format("truetype");
}
@font-face {
  font-family: "Erode Regular";
  src: url("/public/font/Erode-Regular.ttf") format("truetype");
}

@keyframes borderAnimation {
  0% {
    width: 0;
  }

  100% {
    width: 100%;
  }
}

.animate-border-left {
  position: relative;
  overflow: hidden;
}

.animate-border-left::before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0;
  height: 1.5px;
  background-color: #3a5a40;
  animation: borderAnimation 1s ease forwards;
}

.shadow-all {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
}

.header,
.modal {
  z-index: 999;
}

.smallScreen {
  z-index: 999;
}

.cardFront,
.cardBack {
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
}

.res-height {
  @media only screen and (min-width: 1537px) {
    width: 170px;
    height: 160px;
  }

  @media only screen and (min-width: 1280px) and (max-width: 1537px) {
    width: 170px;
    height: 160px;
  }

  @media only screen and (max-width: 1280px) {
    height: 170px;
  }

  @media only screen and (max-width: 680px) {
    width: 90px;
    height: 84px;
  }
}

h1 {
  font-size: clamp(36px, 5.3vw, 100px);
  font-family: "Outfit Medium", sans-serif;
}
h2 {
  font-size: clamp(24px, 2.344vw, 45px);
  font-family: "Outfit ExtraBold", sans-serif;
}
h3 {
  font-size: clamp(24px, 1.875vw, 36px);
  font-family: "Outfit SemiBold", sans-serif;
}

h4 {
  font-size: clamp(20px, 1.771vw, 34px);
  font-family: "Outfit SemiBold", sans-serif;
}

h5 {
  font-size: clamp(14px, 1.667vw, 32px);
  font-family: "Outfit SemiBold", sans-serif;
}
h6 {
  font-size: clamp(14px, 1.459vw, 28px);
  font-family: "Outfit Medium", sans-serif;
}

h7 {
  font-size: clamp(14px, 1.1459vw, 22px);
  font-family: "Outfit Medium", sans-serif;
}

.title1 {
  font-size: clamp(18px, 1.3542vw, 26px);
  font-family: "Outfit SemiBold", sans-serif;
}

.title2 {
  font-size: clamp(16px, 1.25vw, 24px);
  font-family: "Outfit Medium", sans-serif;
}

.title3 {
  font-size: clamp(20px, 1.563vw, 30px);
  font-family: "Outfit Bold", sans-serif;
}
.title4 {
  font-size: clamp(16px, 1.1459vw, 22px);
  font-family: "Outfit SemiBold", sans-serif;
}

.body1 {
  font-size: clamp(24px, 1.459vw, 28px);
  font-family: "Outfit Regular", sans-serif;
}
.body2 {
  font-size: clamp(20px, 1.25vw, 24px);
  font-family: "Outfit Medium", sans-serif;
}
.body3 {
  font-size: clamp(16px, 1.1459vw, 22px);
  font-family: "Outfit Medium", sans-serif;
}
.body4 {
  font-size: clamp(16px, 1.042vw, 20px);
  font-family: "Outfit Regular", sans-serif;
}
.body5 {
  font-size: clamp(14px, 0.9375vw, 18px);
  font-family: "Outfit Regular", sans-serif;
}
.body6 {
  font-size: clamp(12px, 0.8334vw, 16px);
  font-family: "Outfit Light", sans-serif;
}

::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

::-webkit-scrollbar-track {
  background-color: rgba(128, 128, 128, 0.1);
  @apply opacity-20;
  border: 1px solid rgba(174, 162, 162, 0.596) F4F8;
  border-radius: 10px;
  height: 1px;
}

::-webkit-scrollbar-thumb {
  width: 10px;
  height: 10px;
  cursor: pointer;
  background: rgba(174, 162, 162, 0.596);

  border-radius: 10px;
}

/* Style for the Google Translate */

.goog-logo-link {
  display: none !important;
}

.goog-te-gadget {
  color: transparent !important;
}
.goog-te-gadget {
  font-size: 0px !important;
}

.goog-te-gadget .goog-te-combo {
  color: #222222 !important;
  font-family: "OutFit Light";
}

.goog-te-gadget {
  font-size: 0px;
}

#google_translate_element img {
  display: none !important;
}

#google_translate_element {
  color: transparent;
}
#google_translate_element a {
  display: none;
}

* {
  /* border: 1px solid red; */
}

section.orange {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;

  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: orange;
}

section.black {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  -webkit-justify-content: space-around;
  -ms-flex-pack: distribute;
  justify-content: space-around;
}

section.blue {
  background-color: blue;
}

.text {
  color: black;
}

.panel-text {
  position: relative;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: 0%;
  z-index: 1;
  width: 100%;

  /*---*/
  height: 100vh;

  font-size: 40px;
  text-transform: uppercase;
  font-weight: 900;
  text-align: center;
  background-color: white;

  display: flex;
  align-items: center;
  justify-content: center;
}

.footerContent {
  height: 10vh;
  background-color: #222222;
  width: 100%;
}



.css-1olehw2-MuiPaper-root-MuiAccordion-root{
  background-color: #f6f6f6;
}

.product-card-shadow {
  box-shadow: 0px 20px 20px rgba(0, 0, 0, 0.11), 0px 11.0797px 26.8598px rgba(35, 134, 167, 0.079074), 0px 5.92371px 14.3605px rgba(35, 134, 167, 0.0655718), 0px 3.32078px 8.05039px rgba(35, 134, 167, 0.055), 0px 1.76364px 4.2755px rgba(35, 134, 167, 0.0444282), 0px 0.733892px 1.77913px rgba(35, 134, 167, 0.030926);
}



.contact-card-bg-shadow{
  border-radius: 12px;
background: #FDFDFD;
box-shadow: 2px 2px 16px 0px rgba(0, 0, 0, 0.13);
}

.custom-link:hover {
  @apply animate-border-left font-[600] text-oliveGreen;
}




::-moz-selection{
  color:'white';
  background:'#FFFFFF';
}

* {
 
  -webkit-tap-highlight-color: transparent;
}


.loader {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px; 
}

.circle {
  width: 20px; 
  height: 20px;
  background-color: #333; 
  border-radius: 50%;
  margin: 0 5px; 
  animation: scaleUpDown 1s infinite alternate;
}

@keyframes scaleUpDown {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.2);
  }
}

